const STEP = 15; // minutes

const DEFAULT_TIMES_15_MIN = Array.apply(null, {
  length: 24 * (60 / STEP)
}).map((v, i) => {
  const h = Math.floor(i * (STEP / 60));
  const m = i * STEP - h * 60;

  return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`;
});


export default DEFAULT_TIMES_15_MIN;
