import HttpService from './HttpService';

class NotificationsService extends HttpService {
  findAll(confId) {
    return this.http
      .get('/push_notification', {
        params: {
          conf_id: `eq.${confId}`,
          order: 'date'
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  sendOne(id) {
    return this.http
      .post('/notifications/send-one', { id }, {
        baseURL: process.env.REACT_APP_SERVICES_URL
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  create(payload) {
    return this.http
      .post('/push_notification', payload, {
        headers: this.defaultHeaders
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  update(payload) {
    return this.http
      .patch('/push_notification', payload, {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${payload.id}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }

  delete(id) {
    return this.http
      .delete('/push_notification', {
        headers: this.defaultHeaders,
        params: {
          id: `eq.${id}`
        }
      })
      .then(this.onResponse)
      .catch(this.onError);
  }
}

const notificationsService = new NotificationsService();

export default notificationsService;
