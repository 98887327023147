import React, { useState } from 'react';
import OnBoardingImage from '../../assets/undraw_onboarding.png';
import Modal, { ModalTransition, ModalBody } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { setDone } from '../../services/OnboardingService';
import Confetti from 'react-dom-confetti';
import { HomeTour } from './components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { setShowOnboarding } from '../../store/actions/confs';
import AdaptiveModalFooter from '../utils/adaptive-modal-footer';

const Image = styled.img`
  height: auto;
  width: 100%;
  margin-top: 10px;
`;

const ConfettiWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 65%;
  z-index: 500;
`;

// eslint-disable-next-line
export default ({ useComponent = false, onStart }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showOnboarding = useMappedState(state => state.confs.showOnboarding);
  const [showTour, setShowTour] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const onAccept = () => {
    if (!useComponent) {
      onStart();
    }
    dispatch(setShowOnboarding(false));
    setShowTour(true);
  };

  const onDecline = () => {
    dispatch(setShowOnboarding(false));
    setDone();
  };

  const onFinishedOnboarding = (confetti = false) => {
    setShowConfetti(confetti);
    setShowTour(false);
    setDone();
  };

  return (
    <>
      <ModalTransition>
        {showOnboarding && (
          <Modal shouldScrollInViewport>
            <ModalBody>
              <Image src={OnBoardingImage} />
              <div style={{ textAlign: 'center' }}>
                <h2>{t('onboarding:modal.title')}</h2>
                <p>{t('onboarding:modal.description')}</p>
              </div>
            </ModalBody>
            <AdaptiveModalFooter>
              <Button appearance="primary" onClick={onAccept} autoFocus>
                {t('onboarding:modal.accept')}
              </Button>
              <Button appearance="subtle" style={{ marginLeft: '8px' }} onClick={onDecline}>
                {t('onboarding:modal.decline')}
              </Button>
            </AdaptiveModalFooter>
          </Modal>
        )}
      </ModalTransition>
      <ConfettiWrapper>
        <Confetti active={showConfetti} />
      </ConfettiWrapper>
      {useComponent && showTour && <HomeTour finishOnboarding={onFinishedOnboarding} />}
    </>
  );
};
